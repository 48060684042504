
import { defineComponent, onMounted } from "vue";
import RecentPolicyTable from "@/components/individual/RecentPolicyTable.vue";
import DashboardClaimSummary from "@/components/individual/DashboardClaimSummary.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "dashboard-view",
  components: {
    RecentPolicyTable,
    DashboardClaimSummary,
  },
  data() {
    return {
      claimsSummary: {},
      policySummary: {},
      policies: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Personal Dashboard");
    });
  },
  mounted() {
    this.getDashboardSummary();
  },
  created() {
    //Set page title
    document.title = "Dashboard | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getDashboardSummary() {
      //Fetch individual client dashboard data
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.INDIVIDUAL_CLIENT_DASHBOARD)
          .then(({ data }) => {
            //Assign data to props
            this.claimsSummary = data.data.claimsSummary;
            this.policySummary = data.data.policySummary;
            this.policies = data.data.policies;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
