import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardClaimSummary = _resolveComponent("DashboardClaimSummary")!
  const _component_RecentPolicyTable = _resolveComponent("RecentPolicyTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardClaimSummary, {
      "widget-classes": "card-xl-stretch mb-xl-8",
      "widget-color": "danger",
      "chart-height": "200",
      "stroke-color": "#cb1e46",
      "claims-summary": _ctx.claimsSummary
    }, null, 8, ["claims-summary"]),
    _createVNode(_component_RecentPolicyTable, {
      "widget-classes": "card-xxl-stretch mt-4 mb-5 mb-xl-8",
      "policy-summary": _ctx.policySummary,
      policies: _ctx.policies
    }, null, 8, ["policy-summary", "policies"])
  ], 64))
}