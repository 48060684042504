
import { defineComponent, onMounted } from "vue";
import PolicyTableDropdownOptions from "@/components/individual/PolicyTableDropdownOptions.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import variables from "@/router/api";
import $ from "jquery";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "recent-policies-table",
  components: { PolicyTableDropdownOptions },
  props: {
    widgetClasses: String,
    policySummary: Object,
    policies: Array,
  },
  mounted() {
    this.displayDatatable();
  },
  setup() {
    const { t, te } = useI18n();

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      translate,
    };
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
          destroy: true,
        });
      }, 3000);
    },
    monthsCalculator(startDate) {
      const month = variables.monthsCalculator(
        startDate,
        variables.currentDate()
      );

      return month == 3
        ? "month-1"
        : month == 2
        ? "month-2"
        : month == 1
        ? "month-3"
        : "";
    },
  },
});
